<template>
<div id=MainPage>
    <v-content>
      <section id="home">
        <v-row no-gutters>
          <v-img
            :min-height="'calc(100vh - ' + $vuetify.application.top + 'px)'"
            src="../assets/mainPicture.jpg"
          >
            <v-theme-provider dark>
              <v-container fill-height>
                <v-row
                  align="center"
                  class="white--text mx-auto"
                  justify="center"
                >
                  <v-col
                    class="white--text text-center"
                    cols="12"
                    tag="h1"
                  >
                    <span
                    :class="[$vuetify.breakpoint.smAndDown ? 'display-1' : 'display-2']"
                      class="font-weight-light">
                      Üdv
                    </span>
                    <br>
                    <span
                      :class="[$vuetify.breakpoint.smAndDown ? 'display-3': 'display-4']"
                      class="font-weight-black" >
                      Berengar Photography
                    </span>
                    <br>
                    <span
                    :class="[$vuetify.breakpoint.smAndDown ? 'display-1' : 'display-2']"
                      class="font-weight-light">
                      oldalán
                    </span>
                  </v-col>
                  <v-btn
                    class="align-self-end"
                    fab
                    outlined
                    @click="$vuetify.goTo('#about-me')"
                  >
                    <v-icon>mdi-chevron-double-down</v-icon>
                  </v-btn>
                </v-row>
                <v-row></v-row>
                <v-row></v-row>
                <v-row></v-row>
                <v-row></v-row>
              </v-container>
            </v-theme-provider>
          </v-img>
        </v-row>
      </section>

      <section id="about-me" class="grey lighten-1">
        <div class="py-12"></div>

        <v-container class="text-center">
          <h2 class="display-2 font-weight-bold mb-3">Magamról</h2>

          <v-responsive
            class="mx-auto mb-8"
            width="56"
          >
            <v-divider class="mb-1"></v-divider>

            <v-divider></v-divider>
          </v-responsive>

          <v-responsive
            class="mx-auto title font-weight-light mb-8"
            max-width="720"
          >
            1995-ben Budapesten születtem. Itt is tanultam gimnáziumi éveimben. 2015-ben felvételt nyertem a Kaposvári Egyetem Természetvédelmi mérnök szakra, így ideiglenesen Kaposvárra költöztem. Terepgyakorlataim során fedeztem fel a fotózás világát. Elsőként tájképeket, természetfotókat készítettem. 2016-ban beneveztem egy fotóversenyre, melyen különdíjas helyezést értem el. Ezután folyamatosan képeztem magamat. Több pályázaton is részt vettem, számos díjat, helyezést értem el. Ezek közül számomra legértékesebb a Balatonszárszói eredményem, melyen 2. helyezést értem el. Egy olyan zsűri bírálta és találta különlegesnek a munkámat, mely ebben a szakmában nagy szónak számít. Egyetemi tanulmányaimat befejezve visszaköltöztem Budapestre, majd beiratkoztam a Fotográfus és fototermék kereskedő OKJ képzésre, melyet kítűnő eredménnyel végeztem el. 
          </v-responsive>
          <v-avatar
            class="elevation-12 mb-12"
            size="128"
          >
            <v-img src="../assets/profilePicture.jpg"></v-img>
          </v-avatar>
        </v-container>
      </section>
      <section
        id="features" class="grey lighten-3 ">
        <div class="py-12"></div>
        <v-container class="text-center" >
          <h2 class="display-2 font-weight-bold mb-3">Szolgáltatások</h2>

          <v-responsive
            class="mx-auto mb-12"
            width="56"
          >
            <v-divider class="mb-1"></v-divider>
            <v-divider></v-divider>
          </v-responsive > 
          <v-row justify="center">
            <v-col cols="12" md="10" lg="8" >
              <v-container class="mx-auto title font-weight-light">
                Professzionális képek és garantált minőség, melyet kedvező áron biztosítok.
                <br/>
                A követekező témákban tudsz nálam időpontot foglalni: (Ezekre példát a galériában találsz.)
                <br/>
                További információkért, időpontokért keress bátran telefonon vagy email-ben.
                <br/>
                <br/>
                <v-btn
                    class="align-self-end"
                    fab
                    outlined
                    @click="$vuetify.goTo('#contact')"
                >
                  <v-icon>mdi-chevron-double-down</v-icon> 
                </v-btn>
                <div class="py-2"></div>
                <v-expansion-panels accordion>
                  <v-expansion-panel
                    v-for="service in services"
                    :key="service.id"
                  >
                    <v-expansion-panel-header class="title" >{{service.title}}</v-expansion-panel-header>
                    <v-expansion-panel-content class="text-left">
                      {{service.text}}
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-container>
            </v-col>
          </v-row>
        </v-container>
      </section>
      <section
        id="features" class="grey lighten-3">
        <div class="py-12"></div>
        <v-container class="text-center">
          <h2 class="display-2 font-weight-bold mb-3">Galériák</h2>

          <v-responsive
            class="mx-auto mb-12"
            width="56"
          >
            <v-divider class="mb-1"></v-divider>

            <v-divider></v-divider>
          </v-responsive>
        </v-container>
      </section>
      <section id="categories" class="grey lighten-3">
        <v-container>
        <v-row>
            <v-col
              v-for="category in categories"
              :key="category.id"
              cols="12"
              xl="3"
              md="4"
              sm="6"
            >
              <v-card
                class="py-4 px-4"
              >
                <v-img
                  class="white--text align-end"
                  height="400px"
                  :src="category.image"
                >
                  <v-card-title v-text="category.title"></v-card-title>
                </v-img>
              <vue-picture-swipe :ref="category.route" :items="category.items" :options="{shareEl: false}"></vue-picture-swipe>
                <v-card-actions>
                  <v-btn
                    color="orange"
                    text
                    v-on:click="navigate(category.route)"
                  >
                    Megtekintés
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </section>

      <section id="stats" class="grey lighten-3">
        <v-parallax
          :height="$vuetify.breakpoint.smAndDown ? 900 : 500"
          src="../assets/parallaxPicture.jpg"
        >
          <v-container fill-height>
            <v-row class="mx-auto">
              <v-col
                v-for="[value, title] of stats"
                :key="title"
                cols="12"
                md="3"
              >
                <div class="text-center">
                  <div
                    class="display-3 font-weight-black mb-4"
                    v-text="value"
                  ></div>

                  <div
                    class="title font-weight-regular text-uppercase"
                    v-text="title"
                  ></div>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-parallax>
        <div class="py-12"></div>
      </section>
      <section id="contact" class="grey lighten-3">
        <v-container class="text-center">
          <h2 class="display-2 font-weight-bold mb-3 text-uppercase text-center">Kapcsolat</h2>

          <v-responsive
            class="mx-auto mb-12"
            width="56"
          >
            <v-divider class="mb-1"></v-divider>

            <v-divider></v-divider>
          </v-responsive>

          <v-theme-provider light>
          <v-row>
            <v-col
              v-for="({ icon, title, text, link, linke}, i) in contacts"
              :key="i"
              cols="12"
              md="3"
            >
              <v-card
                class="py-12 px-4"
                color="grey lighten-3"
                flat

              >
                  <div>
                    <v-avatar
                      color="grey lighten-3"
                      size="100"
                    >
                      <v-icon
                        x-large
                        v-text="icon"
                      ></v-icon>

                    </v-avatar>
                  </div>

                <v-card-title
                  class="justify-center font-weight-black text-uppercase"
                  v-text="title"
                ></v-card-title>
                <div v-if="linke">
                  <v-btn
                    class="subtitle-1 font-weight-black"
                    v-text="text"
                    :href="link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                  </v-btn>
                </div>
                <div v-else>
                  <v-card-text
                    class="subtitle-1 font-weight-black"
                    v-text="text"
                  >
                  </v-card-text>
                </div>
              </v-card>
            </v-col>
          </v-row>
          </v-theme-provider>
        </v-container>

        <div class="py-12"></div>
      </section>
    </v-content>
</div>
</template>

<script>

import PhotoSwipe from 'photoswipe/dist/photoswipe';
import * as DefaultPhotoSwipeUI from 'photoswipe/dist/photoswipe-ui-default';

export default {
    name: 'MainPage',
    data () {
      return {
        contacts: [
          {
            icon: 'mdi-email',
            title: 'Email',
            text: 'berengar001@gmail.com',
            link: 'berengar001@gmail.com',
            linke: false
          },
          {
            icon: 'mdi-phone',
            title: 'Telefon',
            text: '+36 30 434-7349 ',
            link: 'berengar001@gmail.com',
            linke: false
          },
          {
            icon: 'mdi-facebook',
            title: 'Facebook',
            text: '/berengarphotography',
            link: 'https://facebook.com/berengarphotography',
            linke: true
          },
          {
            icon: 'mdi-instagram',
            title: 'Instagram',
            text: '/berengar_photography',
            link: 'https://instagram.com/berengar_photography/',
            linke: true
          },
        ],
        stats: [
          ['138', 'Publikált fotó'],
          ['44', 'Elégedett ügyfél'],
          ['4.8', 'Átlagos értékelés'],
          ['16', 'Díjnyertes fotó']
        ],
        categories: [
          {
            title: 'Díjazott',
            image: require('../assets/awardsPicture.jpg'),
            text: 'Díjazott képeim',
            route: 0,
            items: [{
              src: require('../assets/awardsPicture.jpg'),
              title: '2017. január különdíj - Kaposvármost',
              w: 1280,
              h: 853,
            },
            {
              src: require('../assets/awards/awards2.jpg'),
              title: '2018. január oklevél kiállított - Kaposvármost',
              w: 1280,
              h: 777,
            },
            {
              src: require('../assets/awards/awards3.jpg'),
              title: '2018. október 6.helyezés - Balatonboglár',
              w: 1280,
              h: 853
            },
            {
              src: require('../assets/awards/awards4.jpg'),
              title: '2018. október Kiállított - Temesvár',
              w: 1280,
              h: 853
            },
            {
              src: require('../assets/awards/awards5.jpg'),
              title: '2018. október Kiállított - Temesvár1',
              w: 853,
              h: 1280
            },
            {
              src: require('../assets/awards/awards6.jpg'),
              title: '2018. szeptember győzteskép - Hungarians Around The World',
              w: 1280,
              h: 853
            },
            {
              src: require('../assets/awards/awards7.jpg'),
              title: '2019. augusztus 2.helyezés - Isten a XXI. században',
              w: 1280,
              h: 853
            },
            {
              src: require('../assets/awards/awards8.jpg'),
              title: '2019. január különdíj - Miénk a Deseda',
              w: 1280,
              h: 852
            },
            {
              src: require('../assets/awards/awards9.jpg'),
              title: '2019. január oklevél kiállított - Kaposvármost',
              w: 1280,
              h: 814
            },
            {
              src: require('../assets/awards/awards10.jpg'),
              title: '2019. május Fővárosi Zoo',
              w: 1280,
              h: 889
            },
            {
              src: require('../assets/awards/awards11.jpg'),
              title: '2019. május Fővárosi Zoo',
              w: 1173,
              h: 1280
            },
            {
              src: require('../assets/awards/awards12.jpg'),
              title: '2019. szeptember oklevél kiállított - Nagytarcsa',
              w: 1280,
              h: 853
            },
            {
              src: require('../assets/awards/awards13.jpg'),
              title: '2020. március oklevél kiállított - Temesvár',
              w: 1280,
              h: 922
            },
            {
              src: require('../assets/awards/awards14.jpg'),
              title: '2020. szeptember - Nemzet naptára',
              w: 1280,
              h: 880
            },
          ]},
          {
            title: 'Portré',
            image: require('../assets/portraitPicture.jpg'),
            text: 'Páros, modell, zenekar',
            route: 1,
            items: [{
              src: require('../assets/portraitPicture.jpg'),
              w: 870,
              h: 1280
            },
            {
              src: require('../assets/portrait/portrait1.jpg'),
              w: 866,
              h: 1280
            },
            {
              src: require('../assets/portrait/portre2.jpg'),
              w: 1280,
              h: 853
            },
            {
              src: require('../assets/portrait/portre3.jpg'),
              w: 800,
              h: 1280
            },
            {
              src: require('../assets/portrait/portre4.jpg'),
              w: 872,
              h: 1280
            },
            {
              src: require('../assets/portrait/portre5.jpg'),
              w: 813,
              h: 1280
            },
           {
              src: require('../assets/portrait/portre6.jpg'),
              w: 846,
              h: 1280
            },
            {
              src: require('../assets/portrait/portrait2.jpg'),
              w: 1280,
              h: 921
            },
            {
              src: require('../assets/portrait/portrait3.jpg'),
              w: 1280,
              h: 853
            },
          ]},
          {
            title: 'Családi',
            image: require('../assets/familyPicture.jpg'),
            text: 'Család, gyermek, kismama',
            route: 2,
            items: [{
              src: require('../assets/familyPicture.jpg'),
              w: 853,
              h: 1280,
            },
            {
              src: require('../assets/family/family2.jpg'),
              w: 1280,
              h: 934
            }
          ]},
          {
            title: 'Esemény', 
            image: require('../assets/eventPicture.jpg'),
            text: 'Családi események, rendezvények születésnap, jegyesfotózás, ballagás, stb...',
            route: 3,
            items: [{
              src: require('../assets/eventPicture.jpg'),
              w: 807,
              h: 1280,
            },
            {
              src: require('../assets/event/event2.jpg'),
              w: 1280,
              h: 853
            }
          ]},
          {
            title: 'Kisállat',
            image: require('../assets/pet/kutya1.jpg'),
            text: 'Kis és Nagy Kedvencek',
            route: 4,
            items: [{
              src: require('../assets/petPicture.jpg'),
              w: 861,
              h: 1280,
            },
            {
              src: require('../assets/pet/pet2.jpg'),
              w: 1280,
              h: 853
            },
            {
              src: require('../assets/pet/pet3.jpg'),
              w: 1280,
              h: 929
            },
                        {
              src: require('../assets/pet/kutya1.jpg'),
              w: 888,
              h: 1280
            },
                        {
              src: require('../assets/pet/kutya2.jpg'),
              w: 1280,
              h: 853
            },
                        {
              src: require('../assets/pet/kutya3.jpg'),
              w: 1280,
              h: 853
            },
                        {
              src: require('../assets/pet/kutya4.jpg'),
              w: 1280,
              h: 853
            },
                        {
              src: require('../assets/pet/kutya5.jpg'),
              w: 843,
              h: 1280
            },
                        {
              src: require('../assets/pet/kutya6.jpg'),
              w: 1280,
              h: 859
            },
                        {
              src: require('../assets/pet/kutya7.jpg'),
              w: 853,
              h: 1280
            },
          ]},
          {
            title: 'Tárgy',
            image: require('../assets/object/auto0.jpg'),
            text: 'autó, motor, bicikli, egyéb',
            route: 5,
            items: [{
              src: require('../assets/objectPicture.jpg'),
              w: 1079,
              h: 1280,
            },
            {
              src: require('../assets/object/object1.jpg'),
              w: 1280,
              h: 715
            },
            {
              src: require('../assets/object/object2.jpg'),
              w: 1280,
              h: 853
            },
            {
              src: require('../assets/object/object3.jpg'),
              w: 853,
              h: 1280
            },
            {
              src: require('../assets/object/object4.jpg'),
              w: 802,
              h: 1280
            },
            {
              src: require('../assets/object/auto1.jpg'),
              w: 853,
              h: 1280
            },
            {
              src: require('../assets/object/auto2.jpg'),
              w: 1280,
              h: 853
            },
            {
              src: require('../assets/object/auto3.jpg'),
              w: 1280,
              h: 853
            },
            {
              src: require('../assets/object/auto11.jpg'),
              w: 1280,
              h: 853
            },
            {
              src: require('../assets/object/auto5.jpg'),
              w: 1280,
              h: 853
            },
            {
              src: require('../assets/object/auto0.jpg'),
              w: 838,
              h: 1280
            },
            {
              src: require('../assets/object/auto6.jpg'),
              w: 1280,
              h: 853
            },
            {
              src: require('../assets/object/auto7.jpg'),
              w: 1280,
              h: 853
            },
            {
              src: require('../assets/object/auto8.jpg'),
              w: 1280,
              h: 853
            },
            {
              src: require('../assets/object/auto9.jpg'),
              w: 1280,
              h: 853
            },
            {
              src: require('../assets/object/auto10.jpg'),
              w: 1280,
              h: 853
            },
            {
              src: require('../assets/object/auto12.jpg'),
              w: 1280,
              h: 853
            },
                        {
              src: require('../assets/object/auto13.jpg'),
              w: 1280,
              h: 853
            },
                        {
              src: require('../assets/object/auto14.jpg'),
              w: 1280,
              h: 836
            },
          ]},
        ],
        services: [
        {
          title: 'Portré / Modell / Zenekar / Páros',
          text: 'Országosan, kültéren és beltéren egyaránt az év minden napján. Szolgáltatás díja 10.000 Ft-tól.',
        },
        {
          title: 'Családi',
          text: 'Család, gyermek, kismama. Országosan, kültéren és beltéren egyaránt az év minden napján. Szolgáltatás díja 10.000 Ft-tól.',
        },
        {
          title: 'Esemény',
          text: 'Családi események, rendezvények, születésnap, jegyesfotózás, ballagás stb. Országosan, kültéren és beltéren egyaránt az év minden napján. Szolgáltatás díja 10.000 Ft-tól.',
        },
        {
          title: 'Hobbi állat / Kis és Nagy Kedvencek',
          text: 'Országosan, kültéren és beltéren egyaránt az év minden napján. Szolgáltatás díja 10.000 Ft-tól.',
        },
        {
          title: 'Tárgy',
          text: 'Autó, motor, bicikli stb. Országosan, kültéren és beltéren egyaránt az év minden napján. Szolgáltatás díja 10.000 Ft-tól.',
        },
        ]
     }
   },
    methods: {
      navigate (route) {
          const pswpElement = document.querySelectorAll('.pswp')[0];
          const gallery = new PhotoSwipe(pswpElement, DefaultPhotoSwipeUI,
            this.categories[route].items,
            {
              captionEl: false,
              shareEl: false,
            });
          gallery.init();
        }
      }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
