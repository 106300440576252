import Vue from 'vue'
import App from './App.vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import vuetify from './plugins/vuetify'
import VuePictureSwipe from 'vue-picture-swipe';
import MainPage from './views/MainPage.vue'

Vue.config.productionTip = false
Vue.use(Vuetify)
Vue.component('vue-picture-swipe', VuePictureSwipe);
Vue.component('MainPage', MainPage);

new Vue({
  vuetify,
  render: h => h(App)
}).$mount('#app')